import { useEffect, useRef, useState } from 'react'

import Image from 'next/image'

import {
  type PricesState,
  type TypeUserState,
  sendBeaconData,
  setSponsoredProductsLocalStorage,
  usePrices,
  useUser,
  useWishlist
} from '@k_frontend/core'
import {
  IconCart,
  IconGift,
  IconHeart,
  IconHeartOrange
} from '@k_frontend/icons'
import ProductFooter from 'components/Product/Footer'
import Prices from 'components/Product/Prices'
import SkeletonPrices from 'components/Product/Prices/SkeletonPrices'
import UnavailablePrices from 'components/Product/Prices/UnavailablePrices'
import Stamp from 'components/Stamp'
import Tags from 'components/Tags'
import Tooltip from 'components/Tooltip'
import { useTimestamp } from 'hooks/useTimestamp'
import { OFFER_TYPE } from 'pages/ofertas/ofertas.types'
import { STATIC_KABUM_IMAGES_K5 } from 'settings/kernel'
import theme from 'theme'
import { getTextFromHtml } from 'utils/html-parser'
import { onNextImageError } from 'utils/onNextImageError'
import { pricesErrorNotify } from 'utils/pricesErrorNotify'
import { shallow } from 'zustand/shallow'

import { RatingStars } from '@k_frontend/ui'
import { FreeShippingTag } from 'components/Product/FreeShippingTag'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import useIsVisible from 'hooks/useIsVisible'
import { formatSendBeaconData } from 'utils/formatSendBeaconData'
import ButtonAddRemoveToCart from '../../../ButtonAddRemoveToCart'
import type { ProductProps } from '../../Product.types'
import { ProductGridDesktopLoading } from './ProductGridDesktop.loading'
import * as S from './ProductGridDesktop.styles'

const useUserSelector = (state: TypeUserState) => ({
  isPrime: state.isPrime,
  clientId: state.clientId,
  session: state.session
})

const selectorUsePrices = (state: PricesState, productId: number) => ({
  prices: state.selectorPricesDetails({ state, productId })?.prices,
  loading: state.selectorPricesDetails({ state, productId })?.loading,
  offer: state.selectorPricesDetails({ state, productId })?.offer,
  error: state.pricesDetail[productId]?.error
})

export default function ProductGridDesktop({
  product,
  productPathLink = 'produto',
  update,
  fnOnProductClick,
  isFullPrime,
  smartHintFeature,
  handleClickWishList,
  offerType
}: ProductProps) {
  const productGridDesktopRef = useRef()
  const productId = product?.code
  const isInWishlist = useWishlist((state) => state.isInWishlist)
  const [imageSrc, setImageSrc] = useState(product.thumbnail || product.image)
  const [productImageLoading, setProductImageLoading] = useState(true)
  const [onScreenProductGridDesktop, setOnScreenProductGridDesktop] =
    useState(false)
  const [wasPrintedProductGridDesktop, setWasPrintedProductGridDesktop] =
    useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const mainProductGridDesktopObserver = useIsVisible(
    setOnScreenProductGridDesktop,
    {
      threshold: 0.75
    }
  )

  const {
    isPrime: isUserPrime,
    clientId,
    session
  } = useUser(useUserSelector, shallow)

  const { prices, offer, error } = usePrices(
    (state) => selectorUsePrices(state, productId),
    shallow
  )

  const { timestamp } = useTimestamp()

  const { headerWrapper, headerHover, headerNoHover } = S.CardHeader({
    isHovered
  })

  const isExpired =
    product?.offer?.hashCode && timestamp >= product.offer?.endsAt

  const outOfStock =
    product?.offer?.hashCode && (product.quantity === 0 || update === 0)

  const productStamps = product.stamps
  const isBlackLegendary = productStamps?.type === 'blackLegendary'

  const { flags } = product
  const {
    isFlash,
    isPrime,
    isOffer,
    isOpenbox,
    isPreOrder,
    isSponsored,
    hasGift
  } = flags

  const showPrimeTag = !isUserPrime && isPrime && isOffer

  const isActiveOffer =
    timestamp >= product?.offer?.startsAt || timestamp >= offer?.startsAt

  const isAvailable = product?.available

  const isProductInWishlistAndHasClientId =
    isInWishlist(Number(product.code)) && !!clientId
  const wishlistButtonLabel = `${isProductInWishlistAndHasClientId ? 'Remover da' : 'Adicionar na'} lista de desejos`
  const isFreeShipping =
    flags.isFreeShipping || (flags.isFreeShippingPrime && isUserPrime)

  if (productGridDesktopRef.current && !wasPrintedProductGridDesktop) {
    mainProductGridDesktopObserver?.observe(productGridDesktopRef.current)
  }

  function getIsFullPrime(): boolean {
    return isFullPrime || (isPrime && isUserPrime)
  }

  function getOfferDiscount() {
    const primeDiscount =
      prices?.primeDiscountPercentage || product?.prime?.discountPercentage
    const offerDiscount =
      product?.offer?.discountPercentage || offer?.discountPercentage

    return `-${getIsFullPrime() ? primeDiscount : offerDiscount}%`
  }

  const stockQuantity = offerType
    ? update
    : product?.offer?.quantityAvailable ||
      offer?.quantityAvailable ||
      product?.quantity

  function renderButtonAddToCart() {
    if (offerType === OFFER_TYPE.CLOSED) {
      return (
        <S.linkToProductPage
          href={`/${productPathLink}/${product.code}/${product?.friendlyName}`}
        >
          <IconCart color={theme.colors.black(550)} />
        </S.linkToProductPage>
      )
    }

    if (isAvailable && (offerType !== OFFER_TYPE.SCHEDULED || isActiveOffer)) {
      return (
        <ButtonAddRemoveToCart
          minimalist
          productData={product}
          disabled={error}
        />
      )
    }

    return null
  }

  function getHeaderNoHover() {
    return (
      isAvailable &&
      !error && (
        <div className='flex justify-end items-center h-24 gap-4 w-full'>
          <div className='flex flex-col items-end'>
            {isSponsored && <S.SponsoredText>Patrocinado</S.SponsoredText>}
            {product?.ratingCount > 0 && (
              <div className='flex items-center gap-4'>
                <RatingStars
                  maxRating={5}
                  rating={product.rating}
                  starSize={10}
                />
                <span className='text-xxs text-black-600 leading-none pt-4'>
                  ({product.ratingCount})
                </span>
              </div>
            )}
          </div>
        </div>
      )
    )
  }

  function getProductHeader() {
    return (
      <div className={headerWrapper()}>
        <div className='flex overflow-hidden pr-2'>
          <Stamp stamps={productStamps} />
        </div>
        <m.div
          initial={false}
          animate={{
            rotateX: isHovered ? 180 : 0
          }}
          transition={{ type: 'spring' }}
          style={{
            transformStyle: 'preserve-3d',
            perspective: '1000px'
          }}
        >
          <div
            className={headerNoHover()}
            style={{ backfaceVisibility: 'hidden' }}
          >
            {getHeaderNoHover()}
          </div>
          <div
            className={headerHover()}
            style={{
              backfaceVisibility: 'hidden',
              transform: 'rotateX(180deg)'
            }}
          >
            <div className='flex gap-8'>
              <button
                type='button'
                aria-label={wishlistButtonLabel}
                aria-pressed={isProductInWishlistAndHasClientId}
                className='wishlistCard flex items-center justify-center'
                onClick={() => handleClickWishList()}
              >
                {isProductInWishlistAndHasClientId ? (
                  <IconHeartOrange aria-hidden />
                ) : (
                  <IconHeart aria-hidden />
                )}
              </button>
              {renderButtonAddToCart()}
            </div>
          </div>
        </m.div>
      </div>
    )
  }

  function getProductPrices() {
    if (!timestamp) {
      return <SkeletonPrices />
    }

    if (!isAvailable || error) {
      return (
        <UnavailablePrices
          color={
            getIsFullPrime()
              ? theme.colors.black(900)
              : theme.colors.secondary(500)
          }
        />
      )
    }

    return (
      <Prices
        productId={product.code}
        isPrime={getIsFullPrime()}
        isAvailable={isAvailable}
        isClosedOffer={offerType === OFFER_TYPE.CLOSED}
        outOfStock={outOfStock && offerType !== OFFER_TYPE.CLOSED}
        isScheduledOffer={
          product?.offer?.hashCode && timestamp <= product?.offer?.startsAt
        }
        product={product}
        offerDiscount={getOfferDiscount()}
        isActiveOffer={isActiveOffer}
        isOffer={isOffer}
        stockQuantity={stockQuantity}
        isBlackLegendary={isBlackLegendary}
      />
    )
  }

  function getProductTags() {
    const isPrimeLogo = showPrimeTag

    if (isAvailable && (isPrimeLogo || isOpenbox || isPreOrder || hasGift)) {
      return (
        <div className='flex items-center h-20'>
          <Tags
            className='tagsCard'
            color='primary'
            code={product.code}
            small={true}
            isPrimeLogo={isPrimeLogo}
            isPreOrder={isPreOrder}
            hasGift={hasGift}
            limit={3}
          />
        </div>
      )
    }

    return null
  }

  function renderBadges() {
    if (isFreeShipping) {
      return <FreeShippingTag />
    }

    return null
  }

  useEffect(() => {
    pricesErrorNotify(error)
  }, [error])

  useEffect(() => {
    if (
      product?.newtail &&
      onScreenProductGridDesktop &&
      !wasPrintedProductGridDesktop
    ) {
      sendBeaconData(
        product?.newtail.viewUrl,
        formatSendBeaconData(clientId, session)
      )
      setWasPrintedProductGridDesktop(true)
    }
  }, [onScreenProductGridDesktop])

  return (
    <div className={S.cardWrapper({ type: productStamps?.type || 'normal' })}>
      <LazyMotion features={domAnimation}>
        <S.Product
          ref={productGridDesktopRef}
          className='productCard'
          onClick={() => {
            isSponsored && setSponsoredProductsLocalStorage(product.code)
            fnOnProductClick(product)
          }}
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
        >
          {getProductHeader()}
          <S.ProductBody
            href={`/${productPathLink}/${product.code}/${product?.friendlyName}`}
            className='productLink'
            data-smarthintproductid={product.code.toString()}
            data-smarthintfeature={smartHintFeature}
            hasNoImage={productImageLoading}
          >
            {productImageLoading && <ProductGridDesktopLoading />}
            <Image
              className='imageCard'
              src={imageSrc}
              title={product.name}
              alt={
                getTextFromHtml(product.description)?.substring(0, 150) ||
                product.name
              }
              onError={(e) =>
                setImageSrc(
                  onNextImageError(e, STATIC_KABUM_IMAGES_K5, 'gridCard')
                )
              }
              onLoad={() => setProductImageLoading(false)}
              width={182}
              height={162}
              priority
            />
            <S.BodyInfo>
              <div className='flex items-center relative h-[20px]'>
                {renderBadges()}
                {isFlash && <S.Flash className='flashTagCard' />}
              </div>

              <Tooltip position='right' text={product.name}>
                <S.ContentProductName>
                  <h3>
                    <S.ProductName lines={3} className='nameCard'>
                      {product.name}
                    </S.ProductName>
                  </h3>
                </S.ContentProductName>
              </Tooltip>

              {getProductPrices()}

              {getProductTags()}
            </S.BodyInfo>
          </S.ProductBody>
          <S.ProductFooter>
            <ProductFooter
              product={product}
              isFullPrime={getIsFullPrime()}
              outOfStock={outOfStock}
              isExpired={isExpired}
              isBlackLegendary={isBlackLegendary}
            />
          </S.ProductFooter>
        </S.Product>
      </LazyMotion>
    </div>
  )
}
