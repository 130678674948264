import { usePrices } from '@k_frontend/core'
import theme from 'theme'
import { currency } from 'utils/functions'
import { shallow } from 'zustand/shallow'

import SkeletonPrices from 'components/Product/Prices/SkeletonPrices'
import { DiscountPercentage } from './DiscountPercentage'
import HiddenPrice from './HiddenPrice'
import * as S from './Prices.styles'
import { GetPriceReturn, PricesProps } from './Prices.types'
import { SecondaryPriceInfo } from './SecondaryPriceInfo'

export default function Prices({
  productId,
  isPrime,
  isAvailable,
  product,
  isClosedOffer = false,
  isScheduledOffer,
  outOfStock,
  offerDiscount,
  isActiveOffer,
  isOffer,
  stockQuantity,
  isBlackLegendary
}: PricesProps) {
  const { flags } = product
  const { prices, loading } = usePrices(
    (state) => state.selectorPricesDetails({ state, productId }),
    shallow
  )

  if (loading) {
    return <SkeletonPrices />
  }

  function getPricesInformation(): GetPriceReturn {
    if (isPrime) {
      return {
        oldPrice: product?.oldPrice,
        price: product?.prime?.priceWithDiscount,
        maxInstallment: product?.prime?.maxInstallmentPrime
      }
    }

    if (flags?.isOffer) {
      return {
        oldPrice: product?.oldPrice,
        price: product?.offer?.priceWithDiscount,
        maxInstallment: product?.maxInstallment
      }
    }

    return {
      oldPrice:
        !!product?.oldPrice &&
        product?.oldPrice !== product?.priceWithDiscount &&
        product?.oldPrice !== product?.price
          ? product?.oldPrice
          : 0,
      price: product?.priceWithDiscount,
      maxInstallment: product?.maxInstallment
    }
  }

  function renderPrice() {
    const defaultPrice = currency(
      prices?.priceWithDiscount || getPricesInformation().price
    )

    if (isScheduledOffer) {
      return (
        <HiddenPrice
          color={
            isPrime || isBlackLegendary
              ? theme.colors.black(900)
              : theme.colors.secondary(500)
          }
          price={currency(prices?.oldPrice || getPricesInformation().oldPrice)}
        />
      )
    }

    return (
      <S.Price
        className='priceCard'
        available={isAvailable}
        isPrime={isPrime}
        isClosedOffer={isClosedOffer}
        outOfStock={outOfStock}
        isBlackLegendary={isBlackLegendary}
      >
        {defaultPrice}
      </S.Price>
    )
  }

  return (
    <S.Prices className='availablePricesCard'>
      <div className='flex justify-between items-center gap-8 h-16 '>
        <S.OldPrice className='oldPriceCard'>
          {(prices?.oldPrice || getPricesInformation().oldPrice) > 0 &&
            currency(prices?.oldPrice || getPricesInformation().oldPrice)}
        </S.OldPrice>

        <span className='text-xxs font-semibold text-black-800 group-hover:hidden'>
          {stockQuantity && stockQuantity < 100
            ? `Restam ${stockQuantity} unid.`
            : ''}
        </span>
      </div>
      <div className='flex items-center gap-6 h-[22px] tablet:h-[28px]'>
        {renderPrice()}

        {isOffer && isActiveOffer && (
          <DiscountPercentage
            offerDiscount={offerDiscount}
            isActiveOffer={isActiveOffer}
            isPrime={isPrime}
            isClosedOffer={isClosedOffer || outOfStock}
            isBlackLegendary={isBlackLegendary}
          />
        )}
      </div>
      <SecondaryPriceInfo
        installmentPrice={getPricesInformation().maxInstallment}
        isScheduledOffer={isScheduledOffer}
      />
    </S.Prices>
  )
}
