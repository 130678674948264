import { notify } from 'react-notify-toast'

import { dataLayerEventAddToCart } from '@k_frontend/core'
import { makeLoadIsMobileUseCase } from 'factories/useCases/makeLoadIsMobileUseCase'
import { useCart } from 'hooks/useCart'
import { useMiniCart } from 'hooks/useMiniCart/useMiniCart'
import { addProductToCookies } from 'utils/cart'

import { handleAddToCartWithoutRedirectProps } from './handleAddToCartWithoutRedirect.types'

async function handleOnSuccess() {
  const isMobile = makeLoadIsMobileUseCase().load()

  if (!isMobile) {
    useMiniCart.getState().toggleIsOpen()
    return
  }

  notify.show('Produto adicionado com sucesso no carrinho', 'success')
}

export default function handleAddToCartWithoutRedirect({
  data,
  products,
  parentComponent
}: handleAddToCartWithoutRedirectProps) {
  const setProductsInCart = useCart.getState().setProductsInCart

  const produto = {
    code: data.cart[0]?.codigo.toString(),
    id: data.cart[0]?.id.toString(),
    seller_id: data.cart[0]?.id_vendedor.toString(),
    quantity: data.cart[0]?.quantidade.toString(),
    offer_id: data.cart[0]?.id_marketplace.toString(),
    tag_code: data.cart[0]?.mercadoria_codigo.toString(),
    services: []
  }

  const offer =
    !!products[0]?.offer && Object.keys(products[0]?.offer).length > 0

  if (data.success) {
    const productFormatted = [
      {
        name: products[0].name,
        id: products[0].code.toString(),
        price: products[0].price,
        brand: products[0].manufacturer.name,
        category: products[0].category,
        quantity: 1,
        dimension20: Number(products[0].sellerId),
        dimension21: products[0].sellerName || 'KaBuM!'
      }
    ]
    dataLayerEventAddToCart({
      type: parentComponent,
      payload: productFormatted
    })

    data.cart.forEach((item) => {
      addProductToCookies(item)
    })

    setProductsInCart(produto)
    handleOnSuccess()
  } else if (
    data.errorType === 'NOT_LOGGED' ||
    data.errorType === 'SESSION_EXPIRED'
  ) {
    const currentUrl = window.location.href
    window.location.href = `/login?redirect_uri=${currentUrl}`
  } else if (data.errorType === 'BLOCK_ACQUIRE') {
    notify.show(
      'Ops... Foi atingindo o limite de compra desse produto',
      'error'
    )
  } else if (data.errorType === 'UNAVAILABLE_PRIME') {
    notify.show(
      'Ops... Foi atingindo o limite de compra desse produto Prime',
      'error'
    )
  } else if (
    (data.errorType === 'OUT_OF_STOCK' && offer) ||
    data.errorType === 'UNAVAILABLE'
  ) {
    notify.show('Ops... Parece que essa oferta se esgotou', 'error')
  } else {
    notify.show('Ocorreu um erro ao adicionar o produto no carrinho', 'error')
  }
}
