import { Col, Container, Row, Separator } from '@k_frontend/ui'
import { Root as AccordionRoot } from '@radix-ui/react-accordion'
import * as Dialog from '@radix-ui/react-dialog'
import Service from 'components/PreCheckout/Services/Service'
import { ProductServiceState, useProductService } from 'hooks/useProductService'
import { ServiceStepProps } from './ServiceStep.types'

const useProductServiceSelector = (state: ProductServiceState) => ({
  availableServices: state.availableServices,
  baseProductData: state.baseProductData
})

function ServiceStep({ services }: ServiceStepProps) {
  const { availableServices, baseProductData } = useProductService(
    useProductServiceSelector
  )

  return (
    <>
      <Dialog.Title className='flex gap-12 pb-8 items-center px-16'>
        <span className='text-black-800 text-sm font-semibold'>
          Adicionar serviços para esse produto?
        </span>
      </Dialog.Title>

      <div className='px-16 mb-8'>
        <Separator />
      </div>

      <AccordionRoot
        type='multiple'
        defaultValue={availableServices}
        className='mr-16 overflow-y-auto scrollbar-w-4 scrollbar-bg-black-400 scrollbar-color-secondary-500'
      >
        <Container
          sm={{ overflowLeft: true, overflowRight: true }}
          md={{ overflowLeft: true, overflowRight: true }}
        >
          <Row>
            <Col>
              {services.map((service) => (
                <Service
                  service={service}
                  key={service.code}
                  productCode={baseProductData.productCode}
                  shouldShowAdvantages={false}
                  shouldShowTerms={false}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </AccordionRoot>
    </>
  )
}

export default ServiceStep
